import React, { useState, useEffect } from 'react';
import css from './PriceFilterPlain.module.css';

const RoomFilter = ({ onFilterChange }) => {
  const [bedrooms, setBedrooms] = useState('Any');
  const [beds, setBeds] = useState('Any');
  const [bathrooms, setBathrooms] = useState('Any');

  // Function to create click handler for each filter
  const createClickHandler = (filterType, value) => () => {
    switch (filterType) {
      case 'bedrooms':
        setBedrooms(value);
        break;
      case 'beds':
        setBeds(value);
        break;
      case 'bathrooms':
        setBathrooms(value);
        break;
      default:
      // Unknown filter type
    }
  };

  // Function to determine if the span should have the 'selected' class
  const isSelected = (filterType, value) => {
    switch (filterType) {
      case 'bedrooms':
        return bedrooms === value;
      case 'beds':
        return beds === value;
      case 'bathrooms':
        return bathrooms === value;
      default:
        return false;
    }
  };

  // Update URL query parameters when state changes (use your own logic for URL manipulation)
  useEffect(() => {
    if (onFilterChange) {
      onFilterChange({
        bedrooms,
        beds,
        bathrooms,
      });
    }

    // Existing code for updating URL query parameters
    const params = new URLSearchParams();
    if (bedrooms !== 'Any') params.set('bedrooms', bedrooms);
    if (beds !== 'Any') params.set('beds', beds);
    if (bathrooms !== 'Any') params.set('bathrooms', bathrooms);
    // window.history.replaceState(null, '', `?${params.toString()}`);
  }, [bedrooms, beds, bathrooms, onFilterChange]);

  const renderFilterOptions = (filterType, count) => {
    const options = [];
    for (let i = 0; i <= count; i++) {
      const value = i === 0 ? 'Any' : i === count ? '8+' : i.toString();
      options.push(
        <span
          key={`${filterType}-${value}`}
          className={`${css.filterOption} ${isSelected(filterType, value) ? css.selected : ''}`}
          onClick={createClickHandler(filterType, value)}
        >
          {value}
        </span>
      );
    }
    return options;
  };

  return (
    <div>
      <div className={css.title}>Bedrooms</div>
      <div className={css.roomsWrapper}>{renderFilterOptions('bedrooms', 9)}</div>
      <div className={css.title}>Beds</div>
      <div className={css.roomsWrapper}>{renderFilterOptions('beds', 9)}</div>
      <div className={css.title}>Bathrooms</div>
      <div className={css.roomsWrapper}>{renderFilterOptions('bathrooms', 9)}</div>
    </div>
  );
};

export default RoomFilter;
