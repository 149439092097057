import React from 'react';
import { bool } from 'prop-types';

import PriceFilterPlain from './PriceFilterPlain';
import PriceFilterPopup from './PriceFilterPopup';
import RoomFilter from './RoomFilter';

const PriceFilter = props => {
  const { showAsPopup, marketplaceCurrency, onFilterChange = () => {}, ...rest } = props;

  const renderPriceFilter = showAsPopup ? (
    <PriceFilterPopup marketplaceCurrency={marketplaceCurrency} {...rest} />
  ) : (
    <PriceFilterPlain marketplaceCurrency={marketplaceCurrency} {...rest} />
  );

  return (
    <div>
      <div>{renderPriceFilter}</div>
      <div>
        <RoomFilter onFilterChange={onFilterChange} />
      </div>
    </div>
  );
};

PriceFilter.defaultProps = {
  showAsPopup: false,
};

PriceFilter.propTypes = {
  showAsPopup: bool,
};

export default PriceFilter;
